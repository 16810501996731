import React from 'react';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import MainBody from "./mainbody";
import Demo from "./demo";
import Contact from "./contact";

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Header />
          <div>
            <Switch>
              <Route exact path="/">
                <MainBody />
              </Route>
              <Route path="/demo">
                <Demo />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
