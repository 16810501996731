import React from "react";
import MessageForm from './components/MessageForm';
import './vendor/bootstrap/css/bootstrap.min.css';
import './vendor/icofont/icofont.min.css';
import './css/style.css';

function Demo() {
    return (
        <section id="contact" className="contact">
            <div className="container">

                <div className="section-title">
                    <span>Demo</span>
                    <h2>Demo</h2>
                </div>

                <div className="row">
                    <div className="col-lg-5 section-title">
                        <h1 style={{textAlign: "left"}} >Sign up for a personalized demo</h1>
                        <h4 style={{textAlign: 'left', marginTop: '20px'}} >See how a robust platform on the cloud brings
                            buyers and sellers together to form an efficient market.</h4>
                    </div>

                    <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                        <MessageForm subject='Request a demo'/>
                    </div>
                </div>

            </div>
        </section>
    );
}
export default Demo;