import React from 'react'
import '../../vendor/bootstrap/css/bootstrap.min.css';
import '../../vendor/icofont/icofont.min.css';
import '../../css/style.css';

class MessageForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {name: '', email: '', subject: '', message: ''};
    }
    resetForm() {
        this.setState({name: '', email: '', subject: '', message: ''});
    }
    handleChange(name, value){
        console.log(name + ':' + value);
        let state = this.state;
        state[name] = value;
        this.setState({state});

    }
    handleNameChange(e) {
        console.log('name:' + e.target.value);
        this.setState({name: e.target.value});
    }
    handleEmailChange(e) {
        console.log('email:' + e.target.value);
        this.setState({email: e.target.value});
    }
    handleSubjectChange(e) {
        console.log('subject:' + e.target.value);
        this.setState({subject: e.target.value});
    }
    handleMessageChange(e) {
        console.log('message:' + e.target.value);
        this.setState({message: e.target.value});
    }
    handleSubmit(event) {
        event.preventDefault();
        let state = this.state;
        const io = require('socket.io-client');
        const feathers = require('@feathersjs/client');

//        const socket = io('http://localhost:3030');
        const socket = io('https://tauve.com');
        const app = feathers();
        app.configure(feathers.socketio(socket));
        app.configure(feathers.authentication());
//        app.service('messages').on('created', message => console.log('Created a message', message));
//        app.hooks({
//            error(context) {
//                console.error(`Error in '${context.path}' service method '${context.method}'`, context.error.stack);
//            }
//        });
        app.service('messages').create({
            name: state['name'],
            email: state['email'],
            subject: state['subject'],
            content: state['message']
        });
        this.resetForm();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit} className="msgform">
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="name">Your Name</label>
                        <input type="text" name="name" className="form-control" id="name"
                               onChange={this.handleNameChange.bind(this)}  value={this.state.name}
                               data-rule="minlen:4" data-msg="Please enter at least 4 chars"/>
                        <div className="validate"></div>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="email">Your Email</label>
                        <input type="email" className="form-control" name="email" id="email"
                               onChange={this.handleEmailChange.bind(this)}  value={this.state.email}
                               data-rule="email" data-msg="Please enter a valid email"/>
                        <div className="validate"></div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="subject">Subject</label>
                    <input type="text" className="form-control" name="subject" id="subject"
                           onChange={this.handleSubjectChange.bind(this)}  value={this.state.subject}
                           data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                    <div className="validate"></div>
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea className="form-control" name="message" rows="10"
                              id="message" data-rule="required"
                              onChange={this.handleMessageChange.bind(this)}  value={this.state.message}
                              data-msg="Please write something for us"></textarea>
                    <div className="validate"></div>
                </div>
                <div className="text-center">
                    <button type="submit" onClick={this.handleSubmit.bind(this)} >Send Message</button>
                </div>
            </form>
        );
    }
}
export  default MessageForm;