import React from "react";
import './vendor/bootstrap/css/bootstrap.min.css';
import './vendor/icofont/icofont.min.css';
import './css/style.css';

function Header() {
  return (
      <header id="header" className="fixed-top">
          <div className="container d-flex align-items-center">

              <h1 className="logo mr-auto"><a href="/">TAUVE</a></h1>

              <nav className="nav-menu d-none d-lg-block">
                  <ul>
                      <li className="drop-down"><a href="#taufi">Products</a>
                          <ul>
                              <li><a href="/?#taufi">TauFI</a></li>
                              <li><a href="/?#taufi">TauFI VPN</a></li>
                              <li><a href="/?#taufi">TauFI Cloud</a></li>
                          </ul>
                      </li>
                      <li className="drop-down"><a href="#solutions">Solutions</a>
                          <ul>
                              <li><a href="/?#solutions">Bloomberg analytics integration</a></li>
                              <li><a href="/?#solutions">Reference data integration</a></li>
                              <li><a href="/?#solutions">Commercial mortgage news</a></li>
                          </ul>
                      </li>
                      <li className="drop-down"><a href="/?#services">Services</a>
                          <ul>
                              <li><a href="/?#services">BWIC as Service</a></li>
                              <li><a href="/?#services">Consulting Service</a></li>
                          </ul>
                      </li>
                      <li><a href="/contact">Contact</a></li>

                  </ul>
              </nav>
              <a href="/demo" className="get-started-btn scrollto">DEMO</a>
          </div>
      </header>
  );
}
export default Header;