import React from "react";
import './vendor/bootstrap/css/bootstrap.min.css';
import './vendor/icofont/icofont.min.css';
import './css/style.css';

function Footer() {
    return (
        <div>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row  justify-content-center">
                            <div className="col-lg-6">
                                <h3>Tauve</h3>
                                <p>Advancing technologies for securitized products market</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container footer-bottom clearfix">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Pi Capital, LLC</span></strong>. All Rights Reserved
                    </div>
                    <div className="credits">
                        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                    </div>
                </div>
            </footer>
            <a href="/" className="back-to-top"><i className="icofont-simple-up"></i></a>
        </div>
    );
}

export default Footer;