import React from "react";
import './vendor/bootstrap/css/bootstrap.min.css';
import './vendor/icofont/icofont.min.css';
import './css/style.css';
import MessageForm from "./components/MessageForm";

function Contact() {
    return (
        <section id="contact" className="contact">
            <div className="container">

                <div className="section-title">
                    <span>Contact</span>
                    <h2>Contact</h2>
                </div>

                <div className="row">

                    <div className="col-lg-5 d-flex align-items-stretch">
                        <div className="info">
                            <div className="email">
                                <i className="icofont-envelope"></i>
                                <h4>Email:</h4>
                                <p>info@tauve.com</p>
                            </div>

                            <div className="phone">
                                <i className="icofont-phone"></i>
                                <h4>Call:</h4>
                                <p>+1 732-772-5030</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                        <MessageForm />
                    </div>

                </div>

            </div>
        </section>
    );
}
export default Contact;