import React from "react";
import './vendor/bootstrap/css/bootstrap.min.css';
import './vendor/icofont/icofont.min.css';
import './css/style.css';

function MainBody() {
    return (
        <main id="main">
            <section id="hero" className="d-flex align-items-center">

                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                            <h1 style={{marginBottom: '0.67em'}}>Securitized Products Trading: Digitalized</h1>
                            <h2>Tauve's cloud platform brings buy side and sell side together,
                                enables working from anywhere (office or home),
                                and streamlines every step of trading end to end.
                            </h2>
                            <div className="d-flex">
                                <a href="/demo" className="btn-get-started scrollto">Request Demo</a>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 hero-img">
                            <img src={require('./img/taufi-cloud.gif')} className="img-fluid animated" alt=""/>
                        </div>
                    </div>
                </div>

            </section>

            <section id="taufi" className="taufi section-bg">
                <div className="container">

                    <div className="section-title">
                        <span>TauFI</span>
                        <h2>TauFI</h2>
                        <p>A complete solution for securitized products trading for both the buy side and the sell
                            side.</p>
                        <p>Million dollar infrastructure of major banks now available to the market participants of any
                            scale.</p>
                        <p className="font-italic">
                            No barriers, fully digitized, industrial and battle-tested.
                        </p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                            <div className="icon-box">
                                <div className="icon"><i className="icofont-computer"></i></div>
                                <h4 className="title"><a href="/?#taufi">TauFI Desktop</a></h4>
                                <p className="description">Unrivaled functionality, rich desktop UI, cloud-based
                                    connectivity.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-4 mt-md-0 d-flex align-items-stretch">
                            <div className="icon-box">
                                <div className="icon"><i className="icofont-wifi"></i></div>
                                <h4 className="title"><a href="/?#taufi">TauFI VPN</a></h4>
                                <p className="description">Run TauFI anywhere, perfect for working from home.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 d-flex align-items-stretch">
                            <div className="icon-box">
                                <div className="icon"><i className="icofont-cloud"></i></div>
                                <h4 className="title"><a href="/?#taufi">TauFI Cloud</a></h4>
                                <p className="description">Communicate with trading partners securely and
                                    selectively.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 pt-4 pt-lg-0 content">

                        </div>
                        <div className="col-lg-3 pt-4 pt-lg-0 content">
                            <ul>
                                <li><i className="icofont-check-circled"></i>BWIC management</li>
                                <li><i className="icofont-check-circled"></i>Order management</li>
                                <li><i className="icofont-check-circled"></i>Color and talk</li>
                            </ul>
                        </div>
                        <div className="col-lg-3 pt-4 pt-lg-0 content">
                            <ul>

                                <li><i className="icofont-check-circled"></i>Real time analytics</li>
                                <li><i className="icofont-check-circled"></i>Up-to-date reference data</li>
                                <li><i className="icofont-check-circled"></i>Sales team support</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 content">

                        </div>
                    </div>
                </div>
            </section>

            <section id="solutions" className="services">
                <div className="container">

                    <div className="section-title">
                        <span>Solutions</span>
                        <h2>Solutions</h2>
                        <p>We provide customized solutions to integrate your choice of analytics, reference data and
                            other data streams into
                            your trading platform.</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                            <div className="icon-box">
                                <div className="icon">
                                    <i className="icofont-brainstorming"></i>
                                </div>
                                <h4><a href="/?#solutions">Bloomberg Analytics</a></h4>
                                <p>Incorporate about any services and financial data that Bloomberg offers into your
                                    desktop, via Bloomberg API. </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                            <div className="icon-box">
                                <div className="icon"><i className="icofont-database"></i></div>
                                <h4><a href="/?#solutions">Reference Data</a></h4>
                                <p>We have expertise to integrate reference data on CMBS, RMBS, CLO, agency and
                                    non-agency, from Index, Bloomberg, Trepp.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                            <div className="icon-box">
                                <div className="icon"><i className="icofont-binary"></i></div>
                                <h4><a href="/?#solutions">News</a></h4>
                                <p>We scout the web for news in the residential and commercial mortgage market, and
                                    bring the essence to you.</p>
                            </div>
                        </div>


                    </div>

                </div>
            </section>

            <section id="services" className="services section-bg">
                <div className="container">

                    <div className="section-title">
                        <span>BWIC as Services</span>
                        <h2>BWIC as Services</h2>
                        <p>We create, verify and monitor progress of BWICs for you, so you can concentrate on
                            trading.</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                            <div className="icon-box">
                                <div className="icon">
                                    <i className="icofont-bank-alt"></i>
                                </div>
                                <h4><a href="/?#services">Insititutional Holders</a></h4>
                                <p>Publish BWICs through TauFI Cloud to selected broker dealers.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                            <div className="icon-box">
                                <div className="icon"><i className="icofont-dribbble"></i></div>
                                <h4><a href="/?#services">Broker Dealers</a></h4>
                                <p>Blast lists to selected clients, and receive BWIC publications from bond holders.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                            <div className="icon-box">
                                <div className="icon"><i className="icofont-diamond"></i></div>
                                <h4><a href="/?#services">Tauve Sourced</a></h4>
                                <p>Tauve sources BWICs from bond holders or broker dealers, and publishes according to
                                    the rules of the sources.</p>
                            </div>
                        </div>


                    </div>

                </div>
            </section>

            <section id="portfolio" className="portfolio">
                <div className="container">

                    <div className="section-title">
                        <span>Consulting</span>
                        <h2>Consulting</h2>
                        <p>Want to industrialize your trading infrastructure and technology stack? We provide high
                            quality consulting service
                            at competitive price. Best of all, we move at lightening speed, so your business can move at
                            lightening speed.</p>
                    </div>


                </div>
            </section>

        </main>
    );
}
export default MainBody;